import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import Swiper_Navigation from '../../Swiper/Swiper_Navigation';

const DM_Medical_condition = ({record_data, handle_onchange}) => {
    const [ medical_condition_is_other, setMedical_condition_is_other ] = useState(false);

    //this is used to activate auto_translate_behav_score() when record is download
    useEffect(() => {
        if(Object.keys(record_data).length !== 0) {
            setMedical_condition_is_other(parseInt(record_data['DM_other']) === 1);
            handle_onchange();
        }
    }, [record_data]);

    return (
        <>
            <p className='notoser-reg text-web-black my-4'>ภาวะทางการแพทย์</p>
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 my-4'>
                <div className='w-full h-fit flex justify-start gap-10'>
                    <input type='checkbox' id='check_new_DM' name='check_new_DM' onChange={handle_onchange}/>
                    <p className='notoser-reg text-web-black'>New Diabetes Mellitus</p>
                </div>

                <div className='w-full h-fit flex justify-start gap-10'>
                    <input type='checkbox' id='check_poor_DM' name='check_poor_DM' onChange={handle_onchange}/>
                    <p className='notoser-reg text-web-black'>Diabetes Mellitus (poor control)</p>
                </div>

                <div className='w-full h-fit flex justify-start gap-10'>
                    <input type='checkbox' id='check_GDM' name='check_GDM' onChange={handle_onchange}/>
                    <p className='notoser-reg text-web-black'>Gestational Diabetes Mellitus</p>
                </div>

                <div className='w-full h-fit flex justify-start gap-10'>
                    <input type='checkbox' id='check_DLP' name='check_DLP' onChange={handle_onchange}/>
                    <p className='notoser-reg text-web-black'>Dyslipidemia</p>
                </div>

                <div className='w-full h-fit flex justify-start gap-10'>
                    <input type='checkbox' id='check_WL' name='check_WL' onChange={handle_onchange}/>
                    <p className='notoser-reg text-web-black'>ลดน้ำหนัก</p>
                </div>

                <div className='w-full h-fit flex justify-start gap-10'>
                    <input type='checkbox' id='check_Osteoporosis' name='check_Osteoporosis' onChange={handle_onchange}/>
                    <p className='notoser-reg text-web-black'>Osteoporosis</p>
                </div>

                <div className='w-full h-fit flex justify-start gap-10'>
                    <input type='checkbox' id='check_DM_other' name='check_DM_other' onChange={(e) => {
                        document.getElementById('DM_other_specify').value = '';
                        setMedical_condition_is_other(e.target.checked);
                        handle_onchange();
                    }}/>
                    <p className='notoser-reg text-web-black'>อื่นๆ</p>
                </div>
            </div>

            <p className='notoser-reg text-web-black mt-8 mb-4'>ภาวะทางการแพทย์อื่นๆ (ถ้ามี)</p>
            <input type='text' id='DM_other_specify' name='DM_other_specify' placeholder='ภาวะทางการแพทย์อื่นๆ (ถ้ามี)' disabled={!medical_condition_is_other} onChange={handle_onchange}
            className={'border border-gray-200 focus:border-orange-primary focus:outline-orange-primary rounded-md w-full h-10 px-4 ' + (medical_condition_is_other ? 'bg-white' : 'bg-gray-300 cursor-not-allowed')}></input>
        </>
    )
}

const DM_A1C = ({handle_onchange}) => {
    const ref_swiper = useRef(null);

    function getSwiper_Index(index, max_index) {
        if(index < 0) {
            return 0;
        }
        else if(index > max_index) {
            return max_index;
        }
        else {
            return index;
        }
    }

    return (
        <>
            <h3 className='notoser-bold text-2xl text-orange-primary mt-16 mb-10 text-center sm:text-start'>ผลตรวจระดับ HbA1C (%)</h3>

            <div className='relative w-full h-fit'>
                <div className='absolute top-1/2 -translate-y-1/2 left-0 -translate-x-1/2 w-fit h-fit z-10 hover:-translate-x-2/3 transition diration-300'>
                    <Swiper_Navigation boxicon_name={'chevron-left'} handle_onclick={() => {
                        const index = ref_swiper.current.swiper.activeIndex;
                        ref_swiper?.current?.swiper?.slideToLoop(getSwiper_Index(index - 1, 5));
                    }}/>
                </div>

                <Swiper
                ref={ref_swiper}
                className='w-full h-fit mb-10'
                spaceBetween={20}
                slidesPerView={1}
                breakpoints={{
                    1050: {
                        slidesPerView: 3,
                        spaceBetween: 50,
                    },
                    680: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                }}
                modules={[Pagination]}
                >
                    <SwiperSlide className='w-full h-fit border border-gray-300 rounded-md px-6 py-6 cursor-grab'>
                        <p className='notoser-reg text-web-black mb-4'>ครั้งที่ 1</p>
                        <input type='date' id='HbA1C_Date1' name='HbA1C_Date1' onChange={handle_onchange}
                        className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full h-10 px-4'></input>
                        
                        <p className='notoser-reg text-web-black mt-4 mb-2'>ผลตรวจระดับ HbA1C (%)</p>
                        <input type='number' id='HbA1C1' name='HbA1C1' onChange={handle_onchange}
                        className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-r-md w-full h-10 px-4' placeholder='โปรดระบุระดับ HbA1C (%)'></input>

                        <p className='notoser-semi text-web-black text-center text-xs mt-4'>1 / 3</p>
                    </SwiperSlide>

                    <SwiperSlide className='w-full h-fit border border-gray-300 rounded-md px-6 py-6 cursor-grab'>
                        <p className='notoser-reg text-web-black mb-4'>ครั้งที่ 2</p>
                        <input type='date' id='HbA1C_Date2' name='HbA1C_Date2' onChange={handle_onchange}
                        className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full h-10 px-4 pt-1'></input>
                        
                        <p className='notoser-reg text-web-black mt-4 mb-2'>ผลตรวจระดับ HbA1C (%)</p>
                        <input type='number' id='HbA1C2' name='HbA1C2' onChange={handle_onchange}
                        className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-r-md w-full h-10 px-4 pt-1' placeholder='โปรดระบุระดับ HbA1C (%)'></input>

                        <p className='notoser-semi text-web-black text-center text-xs mt-4'>2 / 3</p>
                    </SwiperSlide>

                    <SwiperSlide className='w-full h-fit border border-gray-300 rounded-md px-6 py-6 cursor-grab'>
                        <p className='notoser-reg text-web-black mb-4'>ครั้งที่ 3</p>
                        <input type='date' id='HbA1C_Date3' name='HbA1C_Date3' onChange={handle_onchange}
                        className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full h-10 px-4 pt-1'></input>
                        
                        <p className='notoser-reg text-web-black mt-4 mb-2'>ผลตรวจระดับ HbA1C (%)</p>
                        <input type='number' id='HbA1C3' name='HbA1C3' onChange={handle_onchange}
                        className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-r-md w-full h-10 px-4 pt-1' placeholder='โปรดระบุระดับ HbA1C (%)'></input>
                    
                        <p className='notoser-semi text-web-black text-center text-xs mt-4'>3 / 3</p>
                    </SwiperSlide>
                </Swiper>

                <div className='absolute top-1/2 -translate-y-1/2 right-0 translate-x-1/2 w-fit h-fit z-10 hover:translate-x-2/3 transition diration-300'>
                    <Swiper_Navigation boxicon_name={'chevron-right'} handle_onclick={() => {
                        const index = ref_swiper.current.swiper.activeIndex;
                        ref_swiper?.current?.swiper?.slideToLoop(getSwiper_Index(index + 1, 5));
                    }}/>
                </div>
            </div>

            
        </>
    )
}

const DM_Diagnosis = ({record_data, handle_onchange}) => {
    const [ diagnosis_is_other, setDiagnosis_is_other ] = useState(false);

    const [ value_diagnosis, setValue_diagnosis ] = useState('ไม่ระบุ');
    const [ value_specify, setValue_specify ] = useState('');

    const ref_select_diagnosis = useRef(null);

    //value states are used to activate auto_translate_behav_score() when record is download
    useEffect(() => {
        if(Object.keys(record_data).length !== 0) {
            setValue_diagnosis(record_data['DM_diagnosis']);
            setValue_specify(record_data['DM_diagnosis_specify']);
        }
    }, [record_data]);

    //this is used to activate auto_translate_behav_score() when record is download
    useEffect(() => {
        if(ref_select_diagnosis.current) {
            const options = Array.from(ref_select_diagnosis.current.options).map(x => x.value);
            const selected_index = options.indexOf(value_diagnosis);
            ref_select_diagnosis.current.selectedIndex = (selected_index === -1) ? (options.length - 1) : selected_index;
            setDiagnosis_is_other((selected_index === -1) || (selected_index === options.length - 1));
            handle_onchange();
        }
    }, [value_diagnosis, value_specify]);

    return (
        <div className='flex flex-col lg:flex-row justify-start gap-3'>
            <div className='w-full lg:w-96 h-fit'>
                <p className='notoser-reg text-web-black mt-4 mb-2'>Nutritional Diagnosis</p>
                <select ref={ref_select_diagnosis} id='dm_select_diagnosis' name='dm_select_diagnosis' className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full lg:w-fit h-10 px-2'
                onChange={(e) => {
                    document.getElementById('DM_diagnosis').value = '';
                    setDiagnosis_is_other(e.target.value === 'อื่นๆ');
                    handle_onchange();
                }}>
                    <option value="ไม่ระบุ">ไม่ระบุ</option>
                    <option value="Inadequate energy intake">01 Inadequate energy intake</option>
                    <option value="Excessive energy intake">02 Excessive energy intake</option>
                    <option value="Inadequate oral intake">03 Inadequate oral intake</option>
                    <option value="Excessive oral intake">04 Excessive oral intake</option>
                    <option value="Inadequate fat intake">05 Inadequate fat intake</option>
                    <option value="Excessive fat intake">06 Excessive fat intake</option>
                    <option value="Inadequate protein intake">07 Inadequate protein intake</option>
                    <option value="Excessive protein intake">08 Excessive protein intake</option>
                    <option value="Inadequate carbohydrate intake">09 Inadequate carbohydrate intake</option>
                    <option value="Excessive carbohydrate intake">10 Excessive carbohydrate intake</option>
                    <option value="Inadequate sodium intake">11 Inadequate sodium intake</option>
                    <option value="Inadequate potassium intake">12 Inadequate potassium intake</option>
                    <option value="Inadequate phosphorus intake">13 Inadequate phosphorus intake</option>
                    <option value="Excessive sodium intake">14 Excessive sodium intake</option>
                    <option value="Excessive potassium intake">15 Excessive potassium intake</option>
                    <option value="Excessive phosphorus intake">16 Excessive phosphorus intake</option>
                    <option value="Underweight">17 Underweight</option>
                    <option value="Unintended weight loss">18 Unintended weight loss</option>
                    <option value="Overweight/Obesity">19 Overweight/Obesity</option>
                    <option value="Food and nutrition related knowledge deficit">20 Food and nutrition related knowledge deficit</option>
                    <option value="Not ready for diet/lifestyle change">21 Not ready for diet/lifestyle change</option>
                    <option value="No nutrition diagnosis at this time">22 No nutrition diagnosis at this time</option>
                    <option value="อื่นๆ">อื่นๆ</option>
                </select>
            </div>

            <div className='grow h-fit'>
                <p className='notoser-reg text-web-black mt-0 lg:mt-4 mb-2'>Nutritional Diagnosis อื่นๆ (กรณีที่ไม่มีในตัวเลือก)</p>
                <input type='text' id='DM_diagnosis_specify' name='DM_diagnosis_specify' disabled={!diagnosis_is_other} onChange={handle_onchange}
                className={'border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full h-10 px-4 ' + (diagnosis_is_other ? 'bg-white' : 'bg-gray-300 cursor-not-allowed')} 
                placeholder='Nutritional Diagnosis อื่นๆ (กรณีที่ไม่มีในตัวเลือก)'></input>
            </div>
        </div>
    )
}

const DM_Follow_up = ({record_data, handle_onchange}) => {
    const [ continue_followup, setContinue_followup ] = useState(false);
    const ref_date = useRef(null);

    useEffect(() => {
        if(Object.keys(record_data).length !== 0) {
            setContinue_followup(record_data['ติดตาม_DM'] === 1);
            handle_onchange();
        }
    }, [record_data]);

    useEffect(() => {
        if(!continue_followup) {
            if(ref_date.current) {
                ref_date.current.value = '';
            }
        }
    }, [continue_followup]);

    return (
        <>
            <p className='notoser-reg text-web-black my-4'>การติดตาม</p>
            <select id='ติดตาม_DM' name='ติดตาม_DM'
            className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full h-10 px-4 py-2 mb-4'
            onChange={(e) => {
                setContinue_followup(e.target.value === '1');
                handle_onchange();
            }}>
                <option value="ไม่ระบุ">โปรดระบุแผนการติดตาม</option>
                <option value="0">ไม่ติดตาม (0)</option>
                <option value="1">ติดตาม (1)</option>
            </select>

            <p className='notoser-reg text-web-black my-4'>วัน F/U</p>
            <input ref={ref_date} type='date' id='วันFU_DM' name='วันFU_DM' disabled={!continue_followup} onChange={handle_onchange}
            className={'border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full h-10 px-4 ' + (continue_followup ? 'bg-white' : 'bg-gray-300 cursor-not-allowed')}></input>
        </>
    )
}

const Form_Accordion_DM = ({record_data, handle_onchange}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [height, setHeight] = useState('0px');
    const contentRef = useRef(null);

    useEffect(() => {
        setHeight(isOpen ? `${contentRef.current.scrollHeight}px` : '0px');
    }, [isOpen]);

    //this is used to activate auto_translate_behav_score() when record is download
    //handle_onchange() to update process icon
    useEffect(() => {
        if(Object.keys(record_data).length !== 0) {
            handle_onchange();
        }
    }, [record_data]);

    return (
        <div className='my-4'>
            <button type='button' id='accordion_dm_header' className={"w-full px-8 py-4 text-left flex justify-between items-center bg-white " + (isOpen ? 'rounded-t-md' : 'rounded-md')} onClick={() => setIsOpen(!isOpen)}>
                <h3 className='notoser-bold text-2xl text-orange-primary text-center sm:text-start'>2&#41; กลุ่มโรค OPD เบาหวาน</h3>
            </button>

            <div ref={contentRef} className="overflow-hidden rounded-b-md transition-all duration-300 ease-in-out" style={{ maxHeight: height }}>
                <div className='pt-8 pb-16 px-8 bg-white'>
                    <DM_Medical_condition record_data={record_data} handle_onchange={handle_onchange}/>
                    <DM_A1C handle_onchange={handle_onchange}/>
                    
                    <h3 className='notoser-bold text-2xl text-orange-primary mt-16 text-center sm:text-start'>การให้โภชนบำบัด</h3>
                    <DM_Diagnosis record_data={record_data} handle_onchange={handle_onchange}/>
                    
                    <p className='notoser-reg text-web-black my-4'>เป้าหมายการให้โภชนบำบัด</p>
                    <textarea id='DM_goal' name='DM_goal' rows={5} placeholder='โปรดระบุเป้าหมายการให้โภชนบำบัด' onChange={handle_onchange}
                    className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full py-3 px-3'></textarea>
                    
                    <DM_Follow_up record_data={record_data} handle_onchange={handle_onchange}/>
                </div>
            </div>
        </div>
    )
}

export default Form_Accordion_DM