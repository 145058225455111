import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import color_codes from './Color_Code_List';
import './form_nutritionintake.css';
import Form_NutritionIntake_Reorderable_list from './Form_NutritionIntake_Reorderable_list';
import Adding_food_panel from './Adding_food_panel';

const Adding_food_panel_accordion = ({food_items, handle_accordion_height_change, handle_custom_height_change, handle_update}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [height, setHeight] = useState(0);
  const [contentHeight, setContentHeight] = useState(0);
  const [customHeight, setCustomHeight] = useState(0);

  const [ db_food_names, setDB_food_names ] = useState([]);
  const [ food_name, setFood_name ] = useState('');
  const [ source, setSource ] = useState('NA');
  const [ meal_index, setMeal_index ] = useState(0);
  const [ comp_name, setComp_name ] = useState('');
  const [ food_amount, setFood_amount ] = useState(0);
  const [ group, setGroup ] = useState('NA');
  const [ is_custom, setIs_custom ] = useState(false);
  const [ custom_na, setCustom_na ] = useState(false);
  const [ custom_portion, setCustom_portion ] = useState('');
  const [ custom_portion_other, setCustom_portion_other ] = useState('');
  const [ custom_portion_weight, setCustom_portion_weight ] = useState(0);
  const [ note, setNote ] = useState('');

  const [ portion, setPortion ] = useState('-');
  const [ portion_weight, setPortion_weight ] = useState(0);
  const [ energy, setEnergy ] = useState(0);
  const [ CHO, setCHO ] = useState(0);
  const [ PRO, setPRO ] = useState(0);
  const [ FAT, setFAT ] = useState(0);
  const [ sodium, setSodium ] = useState(0);

  const color_code_white = '#ffffff';
  const color_code_black = '#000000';
  const [ theme_color, setTheme_color ] = useState(color_code_white);
  const [ light_text, setLight_text ] = useState(color_code_black);
  const [ light_bg, setLight_bg ] = useState(color_code_white);
  const [ light_text_disable, setLight_text_disable ] = useState('#333333');

  const contentRef = useRef(null);
  const ref_food_name = useRef(null);
  const ref_food_source = useRef(null);
  const ref_meal = useRef(null);
  const ref_food_exchange = useRef(null);
  const ref_amount = useRef(null);
  const ref_note = useRef(null);

  useEffect(() => {
    if(contentRef.current) {
      setContentHeight(isOpen ? contentRef.current.scrollHeight : 0);
    }

    handle_accordion_height_change(contentRef.current.scrollHeight);
  }, [isOpen]);

  useEffect(() => {
    setHeight(contentHeight + customHeight);
  }, [contentHeight, customHeight]);

  useEffect(() => {
    if(ref_amount.current) {
      ref_amount.current.value = food_amount;
    }
  }, [food_amount, ref_amount]);

  useEffect(() => {
    if(food_items.length > 0) {
      let result = [];
      food_items.map(x => result.push(x.name));
      setDB_food_names(result);
    }
  }, [food_items]);

  useEffect(() => {
    if(ref_food_exchange.current) {
      if(group !== 'NA') {
        const options = Array.from(ref_food_exchange.current.options);
        const select_index = options.findIndex((x) => x.value === group);
        ref_food_exchange.current.selectedIndex = select_index;
      }
    }
  }, [group]);

  useEffect(() => {
    if(ref_meal.current) {
      const selectedIndex = ref_meal.current.value;
      setTheme_color(selectedIndex === -1 ? color_code_white : color_codes[selectedIndex].color);
      setLight_text(selectedIndex === -1 ? color_code_white : color_codes[selectedIndex].light_text);
      setLight_bg(selectedIndex === -1 ? color_code_white : color_codes[selectedIndex].light_bg);
      setLight_text_disable(selectedIndex === -1 ? color_code_white : color_codes[selectedIndex].light_text_disable);
    }
  }, [meal_index, ref_meal]);

  return (
    <div className='my-4 border border-gray-300 rounded-md'>
      <button type='button' className={"w-full px-8 py-4 text-left border-gray-300 flex justify-between items-center bg-white " + (isOpen ? 'rounded-t-md border-b' : 'rounded-md border-none')} onClick={() => setIsOpen(!isOpen)}>
        <h3 className='notoser-bold text-2xl text-orange-primary text-center sm:text-start'>กล่องเพิ่มข้อมูล</h3>
      </button>

      <div ref={contentRef} className="overflow-hidden rounded-b-md transition-all duration-300 ease-in-out" style={{ height: height + 'px' }}>
        <Adding_food_panel food_items={food_items}
        handle_meal_change={(meal_index) => {
          //nothing to do in this component
        }}
        handle_custom_height_change={(new_height) => {
          setCustomHeight(new_height);
          handle_custom_height_change(new_height);
        }}
        handle_update={(obj) => {
          handle_update(obj);
        }}/>
      </div>
    </div>
  )
}

const Form_Accordion_DietaryNote = forwardRef(({ food_items, handle_update, handle_edit }, ref) => {
  const [ isOpen, setIsOpen ] = useState(true);
  const [ height, setHeight ] = useState('0px');
  const contentRef = useRef(null);

  const [ add_panel_height, setAdd_panel_height ] = useState(0);
  const [ add_custom_height, setAdd_custom_height ] = useState(0);
  const [ list_height, setList_height ] = useState(0);
  const [ list_in_table, setList_in_table ] = useState([]);

  useEffect(() => {
    if(contentRef.current) {
      setHeight(isOpen ? `${contentRef.current.scrollHeight + add_panel_height + add_custom_height + list_height}px` : '0px');
    }
  }, [isOpen, add_panel_height, add_custom_height, list_height]);

  useEffect(() => {
    let result_energy = 0;
    let result_CHO = 0;
    let result_PRO = 0;
    let result_FAT = 0;
    let result_sodium = 0;

    for(let i = 0; i < list_in_table.length; i++) {
      const amount = parseFloat(list_in_table[i].food_amount);

      result_energy += parseFloat(list_in_table[i].energy) * amount;
      result_CHO += parseFloat(list_in_table[i].CHO) * amount;
      result_PRO += parseFloat(list_in_table[i].PRO) * amount;
      result_FAT += parseFloat(list_in_table[i].FAT) * amount;
      result_sodium += parseFloat(list_in_table[i].sodium) * amount;
    }

    handle_update({
      energy: result_energy,
      CHO: result_CHO,
      PRO: result_PRO,
      FAT: result_FAT,
      sodium: result_sodium,
    }, list_in_table);
  }, [list_in_table]);

  useImperativeHandle(ref, () => ({
    update_list_in_table: (prev_obj, new_obj) => {
      const edited_index = list_in_table.findIndex(x => x.id === prev_obj.id);
      let new_result = [...list_in_table];
      new_result[edited_index] = new_obj;
      setList_in_table(new_result);
    },
  }));

  return (
    <div className='my-4'>
      <button type='button' id='accordion_other_header' className={"w-full px-8 py-4 text-left border-b border-gray-300 flex justify-between items-center bg-white " + (isOpen ? 'rounded-t-md' : 'rounded-md')} onClick={() => setIsOpen(!isOpen)}>
        <h3 className='notoser-bold text-2xl text-orange-primary text-center sm:text-start'>ข้อมูลการบริโภคอาหาร</h3>
      </button>

      <div ref={contentRef} className="overflow-hidden rounded-b-md transition-all duration-300 ease-in-out" style={{ maxHeight: height }}>
        <div className='w-full h-fit px-8 md:px-12 pt-10 pb-14 bg-white'>
          <Form_NutritionIntake_Reorderable_list list={list_in_table} paragraph_index={0}
          handle_reorder={(new_list) => {
            const result = [...new_list];
            setList_in_table(result);
          }}
          handle_hight_changed={(new_height) => {
            setList_height(new_height);
          }}
          handle_delete={(index) => {
            let prev = [...list_in_table];
            prev.splice(index, 1);
            setList_in_table(prev);
          }}
          handle_edit={(index) => {
            handle_edit(index);
          }}/>

          <Adding_food_panel_accordion
          food_items={food_items}
          handle_accordion_height_change={(new_height) => {
            setAdd_panel_height(new_height);
          }}
          handle_custom_height_change={(new_height) => {
            setAdd_custom_height(new_height);
          }}
          handle_update={(values) => {
            let result = [...list_in_table];
            result.push(values);
            setList_in_table(result);
          }}/>
        </div>
      </div>
    </div>
  )
});

{
  /*
  const Form_Accordion_DietaryNote = ({food_items, handle_update, handle_edit}) => {
  const [ isOpen, setIsOpen ] = useState(true);
  const [ height, setHeight ] = useState('0px');
  const contentRef = useRef(null);

  const [ add_panel_height, setAdd_panel_height ] = useState(0);
  const [ add_custom_height, setAdd_custom_height ] = useState(0);
  const [ list_height, setList_height ] = useState(0);
  const [ list_in_table, setList_in_table ] = useState([]);

  useEffect(() => {
    if(contentRef.current) {
      setHeight(isOpen ? `${contentRef.current.scrollHeight + add_panel_height + add_custom_height + list_height}px` : '0px');
    }
  }, [isOpen, add_panel_height, add_custom_height, list_height]);

  useEffect(() => {
    let result_energy = 0;
    let result_CHO = 0;
    let result_PRO = 0;
    let result_FAT = 0;
    let result_sodium = 0;

    for(let i = 0; i < list_in_table.length; i++) {
      const amount = parseFloat(list_in_table[i].food_amount);

      result_energy += parseFloat(list_in_table[i].energy) * amount;
      result_CHO += parseFloat(list_in_table[i].CHO) * amount;
      result_PRO += parseFloat(list_in_table[i].PRO) * amount;
      result_FAT += parseFloat(list_in_table[i].FAT) * amount;
      result_sodium += parseFloat(list_in_table[i].sodium) * amount;
    }

    handle_update({
      energy: result_energy,
      CHO: result_CHO,
      PRO: result_PRO,
      FAT: result_FAT,
      sodium: result_sodium,
    }, list_in_table);
  }, [list_in_table]);
  
  return (
    <div className='my-4'>
      <button type='button' id='accordion_other_header' className={"w-full px-8 py-4 text-left border-b border-gray-300 flex justify-between items-center bg-white " + (isOpen ? 'rounded-t-md' : 'rounded-md')} onClick={() => setIsOpen(!isOpen)}>
        <h3 className='notoser-bold text-2xl text-orange-primary text-center sm:text-start'>ข้อมูลการบริโภคอาหาร</h3>
      </button>

      <div ref={contentRef} className="overflow-hidden rounded-b-md transition-all duration-300 ease-in-out" style={{ maxHeight: height }}>
        <div className='w-full h-fit px-8 md:px-12 pt-10 pb-14 bg-white'>
          <Form_NutritionIntake_Reorderable_list list={list_in_table} paragraph_index={0}
          handle_reorder={(new_list) => {
            const result = [...new_list];
            setList_in_table(result);
          }}
          handle_hight_changed={(new_height) => {
            setList_height(new_height);
          }}
          handle_delete={(index) => {
            let prev = [...list_in_table];
            prev.splice(index, 1);
            setList_in_table(prev);
          }}
          handle_edit={(index) => {
            handle_edit(index);
          }}/>

          <Adding_food_panel_accordion
          food_items={food_items}
          handle_accordion_height_change={(new_height) => {
            setAdd_panel_height(new_height);
          }}
          handle_custom_height_change={(new_height) => {
            setAdd_custom_height(new_height);
          }}
          handle_update={(values) => {
            let result = [...list_in_table];
            result.push(values);
            setList_in_table(result);
          }}/>
        </div>
      </div>
    </div>
  )
}
  */
}

export default Form_Accordion_DietaryNote