import React, { useEffect, useRef, useState } from 'react';

const Other_Medical_condition = ({record_data, handle_onchange}) => {
    const [ medical_condition_is_other, setMedical_condition_is_other ] = useState(false);

    //this is used to activate auto_translate_behav_score() when record is download
    useEffect(() => {
        if(Object.keys(record_data).length !== 0) {
            setMedical_condition_is_other(parseInt(record_data['Other_other']) === 1);
            handle_onchange();
        }
    }, [record_data]);

    return (
        <>
            <p className='notoser-reg text-web-black my-4'>ภาวะทางการแพทย์</p>
            <div className='grid grid-cols-3 md:grid-cols-4 gap-5 my-4'>
                <div className='w-full h-fit flex justify-start gap-10'>
                    <input type='checkbox' id='check_Med' name='check_Med' onChange={handle_onchange}/>
                    <p className='notoser-reg text-web-black'>Med</p>
                </div>

                <div className='w-full h-fit flex justify-start gap-10'>
                    <input type='checkbox' id='check_Onco' name='check_Onco' onChange={handle_onchange}/>
                    <p className='notoser-reg text-web-black'>Onco</p>
                </div>

                <div className='w-full h-fit flex justify-start gap-10'>
                    <input type='checkbox' id='check_ไตเทียม' name='check_ไตเทียม' onChange={handle_onchange}/>
                    <p className='notoser-reg text-web-black'>ไตเทียม</p>
                </div>

                <div className='w-full h-fit flex justify-start gap-10'>
                    <input type='checkbox' id='check_เด็ก' name='check_เด็ก' onChange={handle_onchange}/>
                    <p className='notoser-reg text-web-black'>เด็ก</p>
                </div>

                <div className='w-full h-fit flex justify-start gap-10'>
                    <input type='checkbox' id='check_Neuro' name='check_Neuro' onChange={handle_onchange}/>
                    <p className='notoser-reg text-web-black'>Neuro</p>
                </div>

                <div className='w-full h-fit flex justify-start gap-10'>
                    <input type='checkbox' id='check_Cardio' name='check_Cardio' onChange={handle_onchange}/>
                    <p className='notoser-reg text-web-black'>Cardio</p>
                </div>

                <div className='w-full h-fit flex justify-start gap-10'>
                    <input type='checkbox' id='check_GI' name='check_GI' onChange={handle_onchange}/>
                    <p className='notoser-reg text-web-black'>GI</p>
                </div>

                <div className='w-full h-fit flex justify-start gap-10'>
                    <input type='checkbox' id='check_Ortho' name='check_Ortho' onChange={handle_onchange}/>
                    <p className='notoser-reg text-web-black'>Ortho</p>
                </div>

                <div className='w-full h-fit flex justify-start gap-10'>
                    <input type='checkbox' id='check_Other_other' name='check_Other_other' onChange={(e) => {
                        document.getElementById('Other_other_specify').value = '';
                        setMedical_condition_is_other(e.target.checked);
                        handle_onchange();
                    }}/>
                    <p className='notoser-reg text-web-black'>อื่นๆ</p>
                </div>

                <div className='w-full h-fit flex justify-start gap-10'>
                    <input type='checkbox' id='check_tel' name='check_tel' onChange={handle_onchange}/>
                    <p className='notoser-reg text-web-black'>ผู้ป่วยติดต่อทางโทรศัพท์</p>
                </div>
            </div>

            <p className='notoser-reg text-web-black mt-8 mb-4'>ภาวะทางการแพทย์อื่นๆ (ถ้ามี)</p>
            <input type='text' id='Other_other_specify' name='Other_other_specify' placeholder='ภาวะทางการแพทย์อื่นๆ (ถ้ามี)' disabled={!medical_condition_is_other} onChange={handle_onchange}
            className={'border border-gray-200 focus:border-orange-primary focus:outline-orange-primary rounded-md w-full h-10 px-4 ' + (medical_condition_is_other ? 'bg-white' : 'bg-gray-300 cursor-not-allowed')}></input>
        </>
    )
}

const Other_Dignosis = ({record_data, handle_onchange}) => {
    const [ diagnosis_is_other, setDiagnosis_is_other ] = useState(false);

    const [ value_diagnosis, setValue_diagnosis ] = useState('ไม่ระบุ');
    const [ value_specify, setValue_specify ] = useState('');

    const ref_select_diagnosis = useRef(null);

    //value states are used to activate auto_translate_behav_score() when record is download
    useEffect(() => {
        if(Object.keys(record_data).length !== 0) {
            setValue_diagnosis(record_data['Other_diagnosis']);
            setValue_specify(record_data['Other_diagnosis_specify']);
        }
    }, [record_data]);

    //this is used to activate auto_translate_behav_score() when record is download
    useEffect(() => {
        if(ref_select_diagnosis.current) {
            const options = Array.from(ref_select_diagnosis.current.options).map(x => x.value);
            const selected_index = options.indexOf(value_diagnosis);
            ref_select_diagnosis.current.selectedIndex = (selected_index === -1) ? (options.length - 1) : selected_index;
            setDiagnosis_is_other((selected_index === -1) || (selected_index === options.length - 1));
            handle_onchange();
        }
    }, [value_diagnosis, value_specify]);

    return (
        <div className='flex flex-col lg:flex-row justify-start gap-3'>
            <div className='w-full lg:w-96 h-fit'>
                <p className='notoser-reg text-web-black mt-4 mb-2'>Nutritional Diagnosis</p>
                <select ref={ref_select_diagnosis} id='other_select_diagnosis' name='other_select_diagnosis' className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full lg:w-fit h-10 px-2'
                onChange={(e) => {
                    document.getElementById('Other_diagnosis').value = '';
                    setDiagnosis_is_other(e.target.value === 'อื่นๆ');
                    handle_onchange();
                }}>
                    <option value="ไม่ระบุ">ไม่ระบุ</option>
                    <option value="Inadequate energy intake">01 Inadequate energy intake</option>
                    <option value="Excessive energy intake">02 Excessive energy intake</option>
                    <option value="Inadequate oral intake">03 Inadequate oral intake</option>
                    <option value="Excessive oral intake">04 Excessive oral intake</option>
                    <option value="Inadequate fat intake">05 Inadequate fat intake</option>
                    <option value="Excessive fat intake">06 Excessive fat intake</option>
                    <option value="Inadequate protein intake">07 Inadequate protein intake</option>
                    <option value="Excessive protein intake">08 Excessive protein intake</option>
                    <option value="Inadequate carbohydrate intake">09 Inadequate carbohydrate intake</option>
                    <option value="Excessive carbohydrate intake">10 Excessive carbohydrate intake</option>
                    <option value="Inadequate sodium intake">11 Inadequate sodium intake</option>
                    <option value="Inadequate potassium intake">12 Inadequate potassium intake</option>
                    <option value="Inadequate phosphorus intake">13 Inadequate phosphorus intake</option>
                    <option value="Excessive sodium intake">14 Excessive sodium intake</option>
                    <option value="Excessive potassium intake">15 Excessive potassium intake</option>
                    <option value="Excessive phosphorus intake">16 Excessive phosphorus intake</option>
                    <option value="Underweight">17 Underweight</option>
                    <option value="Unintended weight loss">18 Unintended weight loss</option>
                    <option value="Overweight/Obesity">19 Overweight/Obesity</option>
                    <option value="Food and nutrition related knowledge deficit">20 Food and nutrition related knowledge deficit</option>
                    <option value="Not ready for diet/lifestyle change">21 Not ready for diet/lifestyle change</option>
                    <option value="No nutrition diagnosis at this time">22 No nutrition diagnosis at this time</option>
                    <option value="อื่นๆ">อื่นๆ</option>
                </select>
            </div>

            <div className='grow h-fit'>
                <p className='notoser-reg text-web-black mt-0 lg:mt-4 mb-2'>Nutritional Diagnosis อื่นๆ (กรณีที่ไม่มีในตัวเลือก)</p>
                <input type='text' id='Other_diagnosis_specify' name='Other_diagnosis_specify' disabled={!diagnosis_is_other} onChange={handle_onchange}
                className={'border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full h-10 px-4 ' + (diagnosis_is_other ? 'bg-white' : 'bg-gray-300 cursor-not-allowed')} 
                placeholder='Nutritional Diagnosis อื่นๆ (กรณีที่ไม่มีในตัวเลือก)'></input>
            </div>
        </div>
    )
}

const Other_Follow_up = ({record_data, handle_onchange}) => {
    const [ continue_followup, setContinue_followup ] = useState(false);
    const ref_date = useRef(null);

    useEffect(() => {
        if(Object.keys(record_data).length !== 0) {
            setContinue_followup(record_data['ติดตาม_other'] === 1);
            handle_onchange();
        }
    }, [record_data]);

    useEffect(() => {
        if(!continue_followup) {
            if(ref_date.current) {
                ref_date.current.value = '';
            }
        }
    }, [continue_followup]);

    return (
        <>
            <p className='notoser-reg text-web-black my-4'>การติดตาม</p>
            <select id='ติดตาม_other' name='ติดตาม_other'
            className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full h-10 px-4 py-2 mb-4'
            onChange={(e) => {
                setContinue_followup(e.target.value === '1');
                handle_onchange();
            }}>
                <option value="ไม่ระบุ">โปรดระบุแผนการติดตาม</option>
                <option value="0">ไม่ติดตาม (0)</option>
                <option value="1">ติดตาม (1)</option>
            </select>

            <p className='notoser-reg text-web-black my-4'>วัน F/U</p>
            <input ref={ref_date} type='date' id='วันFU_other' name='วันFU_other' disabled={!continue_followup} onChange={handle_onchange}
            className={'border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full h-10 px-4 ' + (continue_followup ? 'bg-white' : 'bg-gray-300 cursor-not-allowed')}></input>
        </>
    )
}

const Form_Accordion_Other = ({record_data, handle_onchange}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [height, setHeight] = useState('0px');
    const contentRef = useRef(null);

    useEffect(() => {
        setHeight(isOpen ? `${contentRef.current.scrollHeight}px` : '0px');
    }, [isOpen]);

    //this is used to activate auto_translate_behav_score() when record is download
    //handle_onchange() to update process icon
    useEffect(() => {
        if(Object.keys(record_data).length !== 0) {
            handle_onchange();
        }
    }, [record_data]);

    return (
        <div className='my-4'>
            <button type='button' id='accordion_other_header' className={"w-full px-8 py-4 text-left flex justify-between items-center bg-white " + (isOpen ? 'rounded-t-md' : 'rounded-md')} onClick={() => setIsOpen(!isOpen)}>
                <h3 className='notoser-bold text-2xl text-orange-primary text-center sm:text-start'>3&#41; กลุ่มโรค OPD อื่นๆ</h3>
            </button>

            <div ref={contentRef} className="overflow-hidden rounded-b-md transition-all duration-300 ease-in-out" style={{ maxHeight: height }}>
                <div className='pt-8 pb-16 px-8 bg-white'>
                    <Other_Medical_condition record_data={record_data} handle_onchange={handle_onchange}/>
                    <Other_Dignosis record_data={record_data} handle_onchange={handle_onchange}/>

                    <p className='notoser-reg text-web-black my-4'>เป้าหมายการให้โภชนบำบัด</p>
                    <textarea id='Other_goal' name='Other_goal' rows={5} placeholder='โปรดระบุเป้าหมายการให้โภชนบำบัด' onChange={handle_onchange}
                    className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full py-3 px-3'></textarea>

                    <Other_Follow_up record_data={record_data} handle_onchange={handle_onchange}/>
                </div>
            </div>
        </div>
    )
}

export default Form_Accordion_Other