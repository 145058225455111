const color_codes = [
    {
        meal: 'NA',
        meal_th: 'โปรดเลือก...',
        color: '#ffffff',
        light_text: '#000000',
        light_bg: '#ffffff',
        light_text_disable: '#e5e7eb',
        light_bg_diable: '#6b7280',
    },
    {
        meal: 'dawn',
        meal_th: 'มื้อว่างเช้ามืด',
        color: '#c69fbb',
        light_text: '#503047',
        light_bg: '#d9bfd2',
        light_text_disable: '#8d898c',
        light_bg_diable: '#d6cbd3',
    },
    {
        meal: 'morning',
        meal_th: 'มื้อเช้า',
        color: '#d9bfd1',
        light_text: '#503047',
        light_bg: '#e2cfdd',
        light_text_disable: '#726f71',
        light_bg_diable: '#d6cbd3',
    },
    {
        meal: 'late_morning',
        meal_th: 'มื้อว่างเช้า',
        color: '#B5D4E3',
        light_text: '#1c3a4a',
        light_bg: '#dae9f1',
        light_text_disable: '#5a656b',
        light_bg_diable: '#dfdede',
    },
    {
        meal: 'noon',
        meal_th: 'มื้อกลางวัน',
        color: '#C9E5F6',
        light_text: '#1c3a4a',
        light_bg: '#e9f4fb',
        light_text_disable: '#6e7d85',
        light_bg_diable: '#e7e7e7',
    },
    {
        meal: 'afternoon',
        meal_th: 'มื้อว่างบ่าย',
        color: '#F7E8A6',
        light_text: '#2f2804',
        light_bg: '#fbf3d0',
        light_text_disable: '#615e4f',
        light_bg_diable: '#e0d4cd',
    },
    {
        meal: 'evening',
        meal_th: 'มื้อเย็น',
        color: '#FFC09F',
        light_text: '#331200',
        light_bg: '#ffdecc',
        light_text_disable: '#665851',
        light_bg_diable: '#d1c2c1',
    },
    {
        meal: 'night',
        meal_th: 'มื้อดึก',
        color: '#1B2A41',
        light_text: '#ffffff',
        light_bg: '#8aabc5',
        light_text_disable: '#525d64',
        light_bg_diable: '#a4b6c4',
    },
]

export default color_codes;