const foodExchange = [
    {category: "ข้าวแป้ง", servingUnit: "ทัพพี", weight: 55, energy: 80, carbohydrates: 18, protein: 2, fat: 0, sodium: 0},
    {category: "เนื้อสัตว์ไขมันต่ำมาก", servingUnit: "ช้อนโตีะ", weight: 15, energy: 17.5, carbohydrates: 0, protein: 3.5, fat: 0.5, sodium: 0},
    {category: "เนื้อสัตว์ไขมันต่ำ", servingUnit: "ช้อนโตีะ", weight: 15, energy: 27.5, carbohydrates: 0, protein: 3.5, fat: 1.5, sodium: 0},
    {category: "เนื้อสัตว์ไขมันปานกลาง", servingUnit: "ช้อนโตีะ", weight: 15, energy: 37.5, carbohydrates: 0, protein: 3.5, fat: 2.5, sodium: 0},
    {category: "เนื้อสัตว์ไขมันสูง", servingUnit: "ช้อนโตีะ", weight: 15, energy: 50, carbohydrates: 0, protein: 3.5, fat: 4, sodium: 0},
    {category: "ผัก ก", servingUnit: "ทัพพี", weight: 50, energy: 0, carbohydrates: 0, protein: 0, fat: 0, sodium: 0},
    {category: "ผัก ข", servingUnit: "ทัพพี", weight: 50, energy: 25, carbohydrates: 5, protein: 2, fat: 0, sodium: 0},
    {category: "ผลไม้", servingUnit: "ส่วน", weight: "-", energy: 60, carbohydrates: 15, protein: 0, fat: 0, sodium: 0},
    {category: "นมปกติ", servingUnit: "แก้ว", weight: 240, energy: 150, carbohydrates: 12, protein: 8, fat: 8, sodium: 0},
    {category: "นมพร่องมันเนย", servingUnit: "แก้ว", weight: 240, energy: 120, carbohydrates: 12, protein: 8, fat: 5, sodium: 0},
    {category: "นมไม่มีไขมัน", servingUnit: "แก้ว", weight: 240, energy: 90, carbohydrates: 12, protein: 8, fat: 0, sodium: 0},
    {category: "นมระเหย", servingUnit: "ครึ่งถ้วย", weight: 120, energy: 150, carbohydrates: 12, protein: 8, fat: 8, sodium: 0},
    {category: "นมผง", servingUnit: "ส่วน (4 ช้อนตวง)", weight: 30, energy: 150, carbohydrates: 12, protein: 8, fat: 8, sodium: 0},
    {category: "นมผงไม่มีไขมัน", servingUnit: "ส่วน (4 ช้อนตวง)", weight: 30, energy: 90, carbohydrates: 12, protein: 8, fat: 0, sodium: 0},
    {category: "ไขมัน", servingUnit: "ช้อนชา", weight: 5, energy: 45, carbohydrates: 0, protein: 0, fat: 5, sodium: 0}
];

export default foodExchange;