import React, { useEffect, useRef, useState } from 'react';
import '../../../../pages/Staff_login/staff_login.css';
import Form_Accordion_Assessment from './Form_Accordion_Assessment';
import Form_Accordion_Result from './Form_Accordion_Result';
import Form_Accordion_DietaryNote from './Form_Accordion_DietaryNote';
import axios from 'axios';
import ModalWaiting from '../../Modal/ModalWaiting/ModalWaiting';
import Adding_food_panel from './Adding_food_panel';
import color_codes from './Color_Code_List';

const Modal_Edit_NutritionIntake = ({isShow, food_items, edit_item, handle_close, handle_update}) => {
  const color_code_white = '#ffffff';
  const [ theme_color, setTheme_color ] = useState(color_code_white);
  const [ meal_index, setMeal_index ] = useState(-1);

  const ref_food_panel = useRef(null);

  useEffect(() => {
    if(isShow) {
      if(ref_food_panel.current) {
        ref_food_panel.current.refresh();
      }
    }
  }, [isShow]); 

  useEffect(() => {
    setMeal_index(edit_item.meal_index);
  }, [edit_item]);

  useEffect(() => {
    setTheme_color(meal_index === -1 ? color_code_white : color_codes[meal_index]?.color);
  }, [meal_index]);

  return (
    <>
        { /* Modal confirm submit */ }
        <div className={'absolute top-0 left-0 w-full h-full z-50 ' + (isShow ? 'flex justify-center items-center' : 'hidden')}>
            <div className='w-11/12 h-5/6 bg-white drop-shadow-md rounded-md pb-8 overflow-y-scroll' style={{backgroundColor: theme_color}}>
              <div className='w-full h-fit flex justify-end mt-8'>
                <div className='w-fit h-fit cursor-pointer px-12' onClick={() => {
                  handle_close();
                  
                  if(ref_food_panel.current) {
                    ref_food_panel.current.close();
                  }
                }}>
                    <box-icon name='x'></box-icon>
                </div>
              </div>

              <Adding_food_panel 
              ref={ref_food_panel}
              food_items={food_items}
              edit_item={edit_item}
              handle_meal_change={(meal_index) => {
                setMeal_index(meal_index);
              }}
              handle_custom_height_change={(new_height) => {
                //nothing to do in this component
              }}
              handle_update={(obj) => {
                handle_update(edit_item, obj);
              }}/>
            </div>
        </div>
        
        { /* Modal backdrop */ }
        <div className={'absolute top-0 left-0 w-screen h-screen bg-black opacity-50 z-40 ' + (isShow ? 'block' : 'hidden')}></div>
    </>
  )
}

const Form_Submit_Button = () => {
  return (
    <div className='w-full h-fit px-8 md:px-12 pt-2 pb-16 bg-white rounded-md'>
      <div className='w-full h-fit grid grid-cols-2 gap-5'>
        <div className='w-full h-fit'>
          <p className='notoser-reg text-web-black mt-16 mb-4'>กรุณาระบุวันที่บริโภค</p>
          <input type='date' placeholder='กรุณาระบุวันที่บริโภค'
          className='bg-white border border-gray-200 focus:border-orange-primary focus:outline-orange-primary rounded-md w-full h-10 px-4 pt-1 cursor-pointer'></input>
        </div>

        <div className='w-full h-fit'>
          <p className='notoser-reg text-web-black mt-16 mb-4'>กรุณาระบุประเภทของวันที่บริโภค</p>

          <select className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full h-10 px-4 mb-4' defaultValue={'N/A'}>
            <option value={'N/A'}>กรุณาระบุประเภทของวันที่บริโภค...</option>
            <option value={'วันธรรมดา'}>วันธรรมดา</option>
            <option value={'วันหยุด'}>วันหยุด</option>
          </select>
        </div>
      </div>

      <p className='notoser-reg text-web-black text-center pt-8 mb-4'>กรุณากดปุ่ม ส่งผลลัพธ์ เพื่อส่งข้อมูลเข้าสู่ระบบ</p>

      <div className='w-full h-fit flex justify-center'>
        <button className='notoser-semi w-60 h-fit bg-orange-primary text-white px-10 py-4 rounded-md hover:bg-orange-bold'>ส่งข้อมูล</button>
      </div>
    </div>
  )
}

const Form_NutritionIntake = () => {
  const [ energy_requirement, setEnergy_requirement ] = useState(-1);
  const [ CHO_requirement, setCHO_requirement ] = useState(55);
  const [ PRO_requirement, setPRO_requirement ] = useState(15);
  const [ FAT_requirement, setFAT_requirement ] = useState(30);
  const [ sodium_requirement, setSodium_requirement ] = useState(2000);
  const [ energy_intake, setEnergy_intake ] = useState(0);
  const [ CHO_intake, setCHO_intake ] = useState(0);
  const [ PRO_intake, setPRO_intake ] = useState(0);
  const [ FAT_intake, setFAT_intake ] = useState(0);
  const [ sodium_intake, setSodium_intake ] = useState(0);

  const [ food_items, setFood_items ] = useState([]); //from DB
  const [ food_intake_items, setFood_intake_items ] = useState([]); //from users added for submit
  const [ food_edit_item, setFood_edit_item ] = useState({}); //select from food_intake_items to edit in modal

  const [ loading_screen, setLoading_screen ] = useState(true);
  const [ modal_edit_foodintake, setModal_edit_foodintake ] = useState(false);

  const ref_dietary_note = useRef(null);

  useEffect(() => {
    let abortcontroller = new AbortController();

    axios.get("https://script.google.com/macros/s/AKfycbziwbtvKXDRXEuoaM4CY5iVgEfqaMO4R1r28hzNeylfUtC7zatySHyJq1jJOyuPER9z/exec", { signal: abortcontroller.signal })
    .then((res) => {
      setFood_items([...res.data]);
    })
    .catch((error) => {
      console.log(error);
    });

    return () => abortcontroller.abort();
  }, []);

  useEffect(() => {
    setLoading_screen(food_items.length === 0);
  }, [food_items]);

  return (
    <>
      <div className='relative staff_login_container'>
        <div className='absolute left-0 top-0 w-screen h-screen z-10 block md:flex justify-end gap-5'>
          <div className='w-screen h-screen overflow-y-auto ml-6 mr-6'>
            <div className='w-full h-fit mt-2 mb-10'>
              <form id="google-sheet" name="google-sheet" className='w-full h-fit'
              onSubmit={(e) => e.preventDefault()}>

                <Form_Accordion_Assessment handle_update={(energy, CHO, PRO, FAT, sodium) => {
                  setEnergy_requirement(energy);
                  setCHO_requirement(CHO);
                  setPRO_requirement(PRO);
                  setFAT_requirement(FAT);
                  setSodium_requirement(sodium);
                }}/>
                
                <div className='my-4'></div>

                <Form_Accordion_Result
                req_energy={energy_requirement}
                req_CHO={CHO_requirement}
                req_PRO={PRO_requirement}
                req_FAT={FAT_requirement}
                req_sodium={sodium_requirement}
                intake_energy={energy_intake}
                intake_CHO={CHO_intake}
                intake_PRO={PRO_intake}
                intake_FAT={FAT_intake}
                intake_sodium={sodium_intake}/>

                <div className='my-4'></div>

                <Form_Accordion_DietaryNote 
                ref={ref_dietary_note}
                food_items={food_items}
                handle_update={(nutrients, foods_list) => {
                  setEnergy_intake(nutrients.energy);
                  setCHO_intake(nutrients.CHO);
                  setPRO_intake(nutrients.PRO);
                  setFAT_intake(nutrients.FAT);
                  setSodium_intake(nutrients.sodium);
                  
                  setFood_intake_items([...foods_list]);
                }}
                handle_edit={(index) => {
                  setFood_edit_item(food_intake_items[index]);
                  setModal_edit_foodintake(true);
                }}/>

                <div className='my-4'></div>

                <Form_Submit_Button/>
              </form>
            </div>
          </div>
        </div>

        <div className='absolute left-0 top-0 w-full h-full bg-black opacity-80 z-0'></div>
      </div>

      {/* Any Modal */}
      <ModalWaiting isShow={loading_screen}/>
      <Modal_Edit_NutritionIntake 
      isShow={modal_edit_foodintake} 
      food_items={food_items} 
      edit_item={food_edit_item}
      handle_close={() => setModal_edit_foodintake(false)}
      handle_update={(prev_obj, new_obj) => {
        ref_dietary_note.current.update_list_in_table(prev_obj, new_obj);
        setFood_edit_item({});
        setModal_edit_foodintake(false);
      }}/> { /* clear edit_food_index and modal_edit_foodintake after using this modal*/ }
    </>
  )
}

export default Form_NutritionIntake