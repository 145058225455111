import React, { useEffect, useRef, useState } from 'react'

const Form_Accordion_Result = (props) => {
  const [isOpen, setIsOpen] = useState(true);
  const [height, setHeight] = useState('0px');
  const contentRef = useRef(null);

  const [ requirement_CHOg, setRequirement_CHOg ] = useState(-1);
  const [ requirement_PROg, setRequirement_PROg ] = useState(-1);
  const [ requirement_FATg, setRequirement_FATg ] = useState(-1);

  const [ calculated_energy, setCalculated_energy ] = useState(0);
  const [ intake_CHO_percent, setIntake_CHO_percent ] = useState(0);
  const [ intake_PRO_percent, setIntake_PRO_percent ] = useState(0);
  const [ intake_FAT_percent, setIntake_FAT_percent ] = useState(0);

  const [ gap_energy, setGap_energy ] = useState(-1);
  const [ gap_CHO, setGap_CHO ] = useState(-1);
  const [ gap_PRO, setGap_PRO ] = useState(-1);
  const [ gap_FAT, setGap_FAT ] = useState(-1);
  const [ gap_sodium, setGap_sodium ] = useState(-1);

  useEffect(() => {
    setHeight(isOpen ? `${contentRef.current.scrollHeight}px` : '0px');
  }, [isOpen]);

  useEffect(() => {
    const energy_requirement = props.req_energy;

    if(energy_requirement === -1) {
      setRequirement_CHOg(-1);
      setRequirement_PROg(-1);
      setRequirement_FATg(-1);

      setCalculated_energy(0);
      setIntake_CHO_percent(0);
      setIntake_PRO_percent(0);
      setIntake_FAT_percent(0);

      setGap_energy(-1);
      setGap_CHO(-1);
      setGap_PRO(-1);
      setGap_FAT(-1);
      setGap_sodium(parseFloat(props.intake_sodium) - parseFloat(props.req_sodium));
      return;
    }

    setRequirement_CHOg(get_requirement_in_gram(energy_requirement, props.req_CHO, 4));
    setRequirement_PROg(get_requirement_in_gram(energy_requirement, props.req_PRO, 4));
    setRequirement_FATg(get_requirement_in_gram(energy_requirement, props.req_FAT, 9));

    setCalculated_energy(
      (parseFloat(props.intake_CHO) * 4) +
      (parseFloat(props.intake_PRO) * 4) +
      (parseFloat(props.intake_FAT) * 9)
    );
  }, [props]);

  useEffect(() => {
    if(props.req_energy !== -1) {
      setGap_energy(parseFloat(props.intake_energy) - parseFloat(props.req_energy));
    }

    if(props.req_CHO !== -1) {
      setGap_CHO(parseFloat(props.intake_CHO) - requirement_CHOg);
    }

    if(props.req_PRO !== -1) {
      setGap_PRO(parseFloat(props.intake_PRO) - requirement_PROg);
    }

    if(props.req_FAT !== -1) {
      setGap_FAT(parseFloat(props.intake_FAT) - requirement_FATg);
    }

    setGap_sodium(parseFloat(props.intake_sodium) - parseFloat(props.req_sodium));
  }, [requirement_CHOg, requirement_PROg, requirement_FATg]);

  useEffect(() => {
    if(calculated_energy !== 0) {
      let percent_CHO = ((parseFloat(props.intake_CHO) * 4) / calculated_energy) * 100;
      let percent_PRO = ((parseFloat(props.intake_PRO) * 4) / calculated_energy) * 100;

      setIntake_CHO_percent(get_one_digit_value(percent_CHO));
      setIntake_PRO_percent(get_one_digit_value(percent_PRO));
      setIntake_FAT_percent(get_one_digit_value(100 - (percent_CHO + percent_PRO)));
    }
  }, [calculated_energy]);

  function get_requirement_in_gram(energy_requirement, percent, energy_density) {
    let result = (energy_requirement * (percent/100)) / energy_density;
    return (Math.round(result * 10)) / 10;
  }

  function get_one_digit_value(value) {
    return Math.round(value*10) / 10;
  }

  return (
    <div className='my-4'>
      <button type='button' className={"w-full px-8 py-4 text-left border-b border-gray-300 flex justify-between items-center bg-white " + (isOpen ? 'rounded-t-md' : 'rounded-md')} onClick={() => setIsOpen(!isOpen)}>
          <h3 className='notoser-bold text-2xl text-orange-primary text-center sm:text-start'>ข้อมูลผู้ป่วย</h3>
      </button>

      <div ref={contentRef} className="overflow-hidden rounded-b-md transition-all duration-300 ease-in-out" style={{ maxHeight: height }}>
        <div className='w-full h-fit px-8 md:px-12 pt-8 pb-16 bg-white'>
          <h3 className='notoser-bold text-2xl text-orange-primary text-center sm:text-start mb-8'>ผลลัพธ์พลังงานและสารอาหาร</h3>
          
          <table className="table-auto w-full h-fit border border-gray-300 border-separate rounded-md py-4">
            <thead>
              <tr className='w-full h-fit border border-gray-300 border-separate'>
                <th>สารอาหาร</th>
                <th>ความต้องการ</th>
                <th>การบริโภค</th>
                <th>ส่วนต่าง</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='pl-4'>พลังงาน</td>
                <td className='text-center'>{(props.req_energy === -1) ? 'N/A' : get_one_digit_value(props.req_energy) + ' kcal'}</td>
                <td className='text-center'>{get_one_digit_value(props.intake_energy) + ' kcal'} {'(≈ ' + get_one_digit_value(calculated_energy) + ' kcal)'}</td>
                <td className={'text-center ' + (gap_energy < 0 ? 'text-red-500' : 'text-green-500')}>{(props.req_energy === -1 && gap_energy === -1) ? 'N/A' : (gap_energy > 0 ? '+' : '') + get_one_digit_value(gap_energy) + ' kcal'}</td>
              </tr>
              <tr>
                <td className='pl-4'>คาร์โบไฮเดรต {gap_CHO}</td>
                <td className='text-center'>{(requirement_CHOg === -1) ? 'N/A (' : get_one_digit_value(requirement_CHOg) + ' g ('} { get_one_digit_value(props.req_CHO) } % {' )'}</td>
                <td className='text-center'>{get_one_digit_value(props.intake_CHO) + ' g'} {'(' + get_one_digit_value(intake_CHO_percent) + ' %)'}</td>
                <td className={'text-center ' + (gap_CHO < 0 ? 'text-red-500' : 'text-green-500')}>{(requirement_CHOg === -1 && gap_CHO === -1) ? 'N/A' : (gap_CHO > 0 ? '+' : '') + get_one_digit_value(gap_CHO) + ' g'}</td>
              </tr>
              <tr>
                <td className='pl-4'>โปรตีน</td>
                <td className='text-center'>{(requirement_PROg === -1) ? 'N/A (' : get_one_digit_value(requirement_PROg) + ' g ('} { get_one_digit_value(props.req_PRO) } % {' )'}</td>
                <td className='text-center'>{get_one_digit_value(props.intake_PRO) + ' g'} {'(' + get_one_digit_value(intake_PRO_percent) + ' %)'}</td>
                <td className={'text-center ' + (gap_PRO < 0 ? 'text-red-500' : 'text-green-500')}>{(requirement_PROg === -1 && gap_PRO === -1) ? 'N/A' : (gap_PRO > 0 ? '+' : '') + get_one_digit_value(gap_PRO) + ' g'}</td>
              </tr>
              <tr>
                <td className='pl-4'>ไขมัน</td>
                <td className='text-center'>{(requirement_FATg === -1) ? 'N/A (' : get_one_digit_value(requirement_FATg) + ' g ('} { get_one_digit_value(props.req_FAT) } % {' )'}</td>
                <td className='text-center'>{get_one_digit_value(props.intake_FAT) + ' g'} {'(' + get_one_digit_value(intake_FAT_percent) + ' %)'}</td>
                <td className={'text-center ' + (gap_FAT < 0 ? 'text-red-500' : 'text-green-500')}>{(requirement_FATg === -1 && gap_FAT === -1) ? 'N/A' : (gap_FAT > 0 ? '+' : '') + get_one_digit_value(gap_FAT) + ' g'}</td>
              </tr>
              <tr>
                <td className='pl-4'>โซเดียม</td>
                <td className='text-center'>{(props.req_sodium === -1) ? 'N/A' : get_one_digit_value(props.req_sodium) + ' mg'}</td>
                <td className='text-center'>{get_one_digit_value(props.intake_sodium) + ' mg'}</td>
                <td className={'text-center ' + (gap_sodium < 0 ? 'text-red-500' : 'text-green-500')}>{(gap_sodium > 0 ? '+' : '') + get_one_digit_value(gap_sodium) + ' mg'}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Form_Accordion_Result