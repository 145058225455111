import React, { useState } from 'react'

const Swiper_Navigation = ({boxicon_name, handle_onclick}) => {
    const [ hover, setHover ] = useState(false);

    return (
        <div className='w-fit h-fit bg-white border border-gray-300 rounded-full p-2 drop-shadow cursor-pointer flex justify-center items-center' 
        onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={handle_onclick}>
            <box-icon name={boxicon_name} color={hover ? '#fe9d6a' : '#222222'}></box-icon>
        </div>
    )
}

export default Swiper_Navigation